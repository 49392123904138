import React, { ForwardedRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Router, { useRouter } from 'next/router';
import Image from 'next/image';

import { Account } from '@pickles/shared/models/user.types';
import { numberToAmountLabel } from '@pickles/shared/utils/helpers';
import { userActions } from '@pickles/shared/redux/actions';

import prebidsIcon from '/public/icons/account/prebidsIcon.svg';
import eBidsIcon from '/public/icons/account/eBidsIcon.svg';
import purchasesIcon from '/public/icons/account/purchasesIcon.svg';
import watchlistIcon from '/public/icons/account/watchlistIcon.svg';
import auctionsIcon from '/public/icons/account/auctionsIcon.svg';
import {
  ProfileMenuBody,
  ProfileMenuContainer,
  ProfileMenuItems,
  ProfileMenuHead,
  ProfileMenuItem,
  ProfileUserName,
  AvailableContainer,
  BlockedContainer,
  ProfileMenuLine,
  ProfileMenuFooter,
  accountAuctionsBack,
  accountPrebidsBack,
  accountEBidsBack1,
  accountEBidsBack2,
  accountPurchasesBack,
  ProfileMenuItemUnits,
  ProfileMenuIcon,
  ProfileMenuArrow,
} from './ProfileMenu.style';
import { i18n } from '../../pages/_app';
import { AppHooks, DashboardHooks, EbidAuctionHooks, ItemHooks } from '../../app/redux/hooks';
import Config from '@pickles/shared/config';
import { version } from '../../package.json';
import jsCookie from 'js-cookie';
import { IApp } from '@pickles/shared/models';

export enum ProfileMenuTitles {
  Auctions = 'Auctions',
  MyPrebids = 'My Prebids',
  MyEBids = 'My eBids',
  Purchases = 'Purchases',
  MyWatchlist = 'My Watchlist',
}
// TODO: route for profile menu
export const profileItems = [
  {
    id: 1,
    icon: auctionsIcon,
    text: i18n.t('labels:drawer_auctions'),
    testKey: 'auctions',
    backColorUnits: accountAuctionsBack,
    unitName: ' live',
    route: '/auctions',
    titleId: ProfileMenuTitles.Auctions,
  },
  {
    id: 2,
    icon: prebidsIcon,
    text: i18n.t('labels:my_prebids'),
    testKey: 'my_max_bids',
    backColorUnits: accountPrebidsBack,
    route: '/profile/maxbids',
    titleId: ProfileMenuTitles.MyPrebids,
  },
  {
    id: 3,
    icon: eBidsIcon,
    text: i18n.t('titles:my_bids'),
    testKey: 'my_ebids',
    backColorUnits: [accountEBidsBack1, accountEBidsBack2],
    route: '/profile/ebids',
    titleId: ProfileMenuTitles.MyEBids,
  },
  {
    id: 4,
    icon: purchasesIcon,
    text: i18n.t('titles:my_purchases'),
    testKey: 'my_purchases',
    backColorUnits: accountPurchasesBack,
    unitName: ' offer',
    route: '/profile/purchases',
    titleId: ProfileMenuTitles.Purchases,
  },
  {
    id: 5,
    icon: watchlistIcon,
    text: i18n.t('labels:drawer_watchlist'),
    testKey: 'my_watchlist',
    backColorUnits: accountAuctionsBack,
    route: '/profile/favorites',
    titleId: ProfileMenuTitles.MyWatchlist,
  },
];

type PropsType = {
  user: Account;
  isOpened: boolean;
};

export const ProfileMenu = React.forwardRef(
  ({ user, isOpened }: PropsType, ref: ForwardedRef<HTMLDivElement>) => {
    const now = AppHooks.useApplicationTimer();
    const currentEnv = AppHooks.useEnvironment();
    const dispatch = useDispatch();
    const router = useRouter();
    const { t } = useTranslation();
    const runningAuctions = DashboardHooks.useRunningAuctions();
    const countsObj = DashboardHooks.useCounts();
    const { winning: ebidWinning, outbid: ebidOutbid } = EbidAuctionHooks.useCounts();
    const watchlistCount = ItemHooks.useFavoritesTotal();

    const profileAssets = {
      [ProfileMenuTitles.Auctions]: runningAuctions?.length,
      [ProfileMenuTitles.MyPrebids]: `${countsObj?.prebidsCt}`,
      [ProfileMenuTitles.MyEBids]: [ebidWinning, ebidOutbid],
      [ProfileMenuTitles.Purchases]: `${countsObj?.offers}`,
      [ProfileMenuTitles.MyWatchlist]: `${watchlistCount}`,
    };

    const renderProfileMenuUnits = (
      backColorUnits: string[],
      title: ProfileMenuTitles,
      testKey: string,
    ) => {
      return backColorUnits?.map((unitBackColor, unitIndex) => {
        const count = profileAssets[title as keyof typeof profileAssets];

        return (
          <ProfileMenuItemUnits
            id={`${testKey}_${unitIndex}_count`}
            key={`profile-units-${unitIndex}`}
            backColorUnits={unitBackColor}
          >
            {count[unitIndex]}
          </ProfileMenuItemUnits>
        );
      });
    };

    const logoutHandler = () => {
      dispatch(userActions.logout());
      router.push('/');
    };

    const handleItemPress = (route: string) => {
      router.push(route);
    };

    const handleProfilePress = () => {
      router.push('/profile');
    };

    const profileMenuItems = profileItems.map((item) => {
      return (
        <ProfileMenuItem
          id={item.testKey}
          key={`profile-menu-item-${item.text}`}
          onClick={() => handleItemPress(item.route)}
        >
          {item.icon && (
            <ProfileMenuIcon>
              <Image src={item.icon} alt={item.text} />
            </ProfileMenuIcon>
          )}
          {item.text}
          {Array.isArray(item.backColorUnits) ? (
            renderProfileMenuUnits(item.backColorUnits, item.titleId, item.testKey)
          ) : (
            <ProfileMenuItemUnits id={`${item.testKey}_count`} backColorUnits={item.backColorUnits}>
              {profileAssets[item.titleId]}
              {item?.unitName}
            </ProfileMenuItemUnits>
          )}
        </ProfileMenuItem>
      );
    });

    const showVersion = () => {
      const handleClick = (event) => {
        if (event.detail === 5) {
          // const currentEnv = Config.getCurrentEnv();
          const newEnv =
            currentEnv === IApp.ENV.PRODUCTION ? IApp.ENV.STAGING : IApp.ENV.PRODUCTION;
          jsCookie.set('env', newEnv);
          router.reload();
        }
      };
      if (!Config.isProd()) {
        const apiLabel = Config.isProdAPI() ? 'Prod API' : 'Test API';
        return (
          <ProfileMenuLine>
            <div>Pickles WebApp v{version}</div>
            <div>
              Build: {apiLabel} ({Config.COMMIT_HASH})
            </div>
            <div>Timer: {now / 1000}</div>
            <button onClick={handleClick}>Switch Environment</button>
          </ProfileMenuLine>
        );
      }
    };

    return (
      <ProfileMenuContainer ref={ref} isOpened={isOpened}>
        <ProfileMenuHead onClick={() => handleProfilePress()}>
          <ProfileUserName id="user_fullname">{user?.lastName}</ProfileUserName>
          <AvailableContainer id="available_balance">
            <div>{t('labels:balance_available_new')}</div>{' '}
            {numberToAmountLabel(user?.availableBalance)}
          </AvailableContainer>
          <BlockedContainer id="blocked_balance">
            <div>{t('labels:blocked_balance')}</div> {numberToAmountLabel(user?.blockedAmount)}
          </BlockedContainer>
          <ProfileMenuArrow id="user_profile" />
        </ProfileMenuHead>
        <ProfileMenuBody>
          <ProfileMenuItems>{profileMenuItems}</ProfileMenuItems>
        </ProfileMenuBody>
        <ProfileMenuFooter>
          <ProfileMenuLine />
          <div id="help_and_support" onClick={() => router.push('/contact-us')}>
            {t('labels:drawer_help_and_support')}
          </div>
          <div id="logout" onClick={logoutHandler}>
            {t('buttons:logout')}
          </div>
          {showVersion()}
        </ProfileMenuFooter>
      </ProfileMenuContainer>
    );
  },
);

ProfileMenu.displayName = 'ProfileMenu';

export default ProfileMenu;
